import React, { FC } from "react";
import { SubCategorie, Variable } from "../../../../types/api/indicators";
import { useTranslation } from "react-i18next";
import { Form, Spinner } from "react-bootstrap";
import "./styles.scss";

export interface TopicDetailTreeProps {
  topic: any; // todo define type
  subcategories: SubCategorie[];
  variables: Variable[];
  onClickOption?: any;
  onClickOptionVariable?: any;
}

const TopicDetailTree: FC<TopicDetailTreeProps> = ({
  onClickOption,
  subcategories,
  variables,
  onClickOptionVariable,
}) => {
  const { t } = useTranslation("topic");

  return (
    <div className="w-100 p-3 shadow h-100">
      <h4 className="mt-3 mb-2">{t("visualization")}</h4>
      <div>
        <div className="mt-4">
          <div className="w-100 d-flex  align-items-center flex-row">
            <div className="topic-tree-container-stepper mb-2"></div>
            <h5>{t("component")}</h5>
          </div>
          <div className="w-100 d-flex justify-content-center flex-row">
            {subcategories.length == 0 && <Spinner animation="border" />}
          </div>
          <div className="topic-tree-container-margin d-flex flex-column">
            {subcategories.map((subTopic: SubCategorie, index: number) => (
              <Form.Check
                onClick={
                  onClickOption ? () => onClickOption(subTopic.id) : () => {} // eslint-disable-line
                }
                key={index}
                inline
                label={subTopic.name}
                name="group1"
                type="radio"
                id={`inline-${"radio"}-${index}`}
              />
            ))}
          </div>
          <hr />
        </div>
      </div>
      <div>
        {variables && variables.length > 0 && (
          <div className="w-100 d-flex align-items-center flex-row ">
            <div className="topic-tree-container-stepper mb-2"></div>
            <h5>{t("variable")}</h5>
          </div>
        )}
        <div className="topic-tree-container-margin d-flex flex-column">
          {variables &&
            Array.isArray(variables) &&
            variables.map((variable: Variable, index: number) => (
              <Form.Check
                onClick={
                  onClickOption
                    ? () => onClickOptionVariable(variable.id)
                    : () => {} // eslint-disable-line
                }
                key={index}
                inline
                label={variable.name}
                name="group1"
                type="radio"
                id={`inline-${"radio"}-${index}`}
              />
            ))}
        </div>
        {variables && <hr />}
      </div>
    </div>
  );
};

export default TopicDetailTree;
