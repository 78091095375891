import React from "react";
import { useTranslation } from "react-i18next";
import { DATA } from "../../../../constants/commond";
import "./styles.scss";

const GeoenvironmentalSection = () => {
  const { t } = useTranslation("about");
  const url = DATA.aboutSection.buttonURL;
  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-center p-5 mb-5 main-geo-section">
      <div className="col-lg-6 col-12 d-flex flex-column align-items-center justify-content-center text-center">
        <h1>{t("geoTitle")}</h1>
        <hr className="geo-divider" />
        <p>{t("geoDescription")}</p>
        <div className="mt-3">
          <a
            className="btn custom-primary-button"
            href={url}
            rel="noreferrer"
            target="_blank"
          >
            {t("geoButton")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default GeoenvironmentalSection;
