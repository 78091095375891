import { Card, Spinner } from "react-bootstrap";
import { STATISTICS_ONE } from "../../../../constants/routes";
import { useNavigate } from "react-router";
import "./styles.scss";
import { SINIA_ICON } from "../../../../assets";
import useIndicators from "../../../../hooks/useIndicators";

const TopicDetailCategories = () => {
  const { loading, indicators } = useIndicators();
  const navigate = useNavigate();

  return (
    <div className="w-100 d-flex flex-column align-items-center mb-5">
      <div className="w-100 d-flex flex-row justify-content-center">
        {loading && <Spinner animation="border" variant="dark" />}
      </div>
      <div className="w-75 d-flex flex-column justify-content-center align-items-center category-container">
        <div
          className={`w-100 d-flex flex-column flex-md-row justify-content-${
            indicators.length < 8 ? "start" : "between"
          }`}
        >
          {indicators.map(
            (
              data: { id: string; name: string; icon_url: any },
              index: number
            ) => (
              <div
                key={index}
                className={`w-100 mb-2 topic-card ${
                  index > 0 && "card-margin"
                }`}
              >
                <Card
                  key={index}
                  onClick={() => {
                    if (data.id) {
                      navigate(STATISTICS_ONE.replace(":id", data.id));
                    }
                  }}
                  className="d-flex flex-column p-3 w-100 h-100 clickable"
                >
                  <div className="mb-2">
                    <img
                      className="topic-card-img"
                      src={data.icon_url ? data.icon_url : SINIA_ICON}
                    />
                  </div>
                  <div className="topic-card-text">{data.name}</div>
                </Card>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TopicDetailCategories;
