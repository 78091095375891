import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CONTACT_ICON_1 } from "../../../assets";
import { DATA } from "../../../constants/commond";
import { DIRECTORY } from "../../../constants/routes";
import OtherMeansFooter from "../../organims/OtherMeansView/OtherMeansFooter";
import OtherMeansInfo from "../../organims/OtherMeansView/OtherMeansInfo";
import OtherMeansIntro from "../../organims/OtherMeansView/OtherMeansIntro";

const EnvironmentalInfo = () => {
  const navigate = useNavigate();
  const directory = DATA.environmental;
  const { t } = useTranslation("platform");

  useEffect(() => {
    document.title = `SINIA - ${t("title", { ns: "contact" })}`;
  }, []);

  return (
    <div className="w-100 d-flex justify-content-md-start justify-content-lg-start flex-column">
      <div className="w-100 d-flex flex-column align-items-center background-color">
        <div className="w-75 align-items-left">
          <OtherMeansIntro title={t("title")} description={t("description")} />
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center background-color">
        <div className="w-75 align-items-left">
          <OtherMeansInfo data={directory} type="environmental" />
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="w-75 align-items-left">
          <OtherMeansFooter
            txt={t("directory", { ns: "otherMeans" })}
            buttonText={t("directoryButton", { ns: "otherMeans" })}
            icon={CONTACT_ICON_1.default}
            buttonEvent={() => {
              navigate(DIRECTORY);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EnvironmentalInfo;
