import React from "react";
import { Button, Card } from "react-bootstrap";
import "./styles.scss";

// TODO: DEFINE IF THIS CARD CAN BE USE IN ANOTHER SECTION FOR MODIFY THE PROPS
export interface FeatureCardProp {
  icon: string;
  txt: string;
  button?: boolean;
  buttonText?: string;
  buttonEvent?: any;
  centered?: boolean;
  shadow?: string;
  boldText?: boolean;
  title?: string;
  handleClick?: () => void;
}

const FeatureCard: React.FC<FeatureCardProp> = ({
  icon,
  txt,
  button = false,
  buttonText = "",
  centered = false,
  boldText = false,
  shadow,
  title,
  buttonEvent,
  handleClick,
}) => {
  return (
    <Card
      onClick={handleClick}
      className={`w-100 h-100 p-4 d-flex flex-column card-container align-items-start ${
        handleClick ? "clickable" : ""
      } ${button ? "justify-content-between" : ""} ${
        shadow ? shadow : "shadow"
      }`}
    >
      <div
        className={`w-100 d-flex align-items-start ${
          centered ? "justify-content-center" : "justify-content-start"
        }`}
      >
        <img alt="Imagen topic" className="card-feature-img" src={icon} />
      </div>
      <div className="w-100 my-2 d-flex align-items-start">
        <strong>{title}</strong>
      </div>
      <div
        className={`w-100 mb-2 d-flex align-items-start text-wrap ${
          centered ? "text-center" : ""
        }`}
      >
        {boldText ? (
          <strong className="card-text-style overflow-hidden text-wrap">
            {txt}
          </strong>
        ) : (
          txt
        )}
      </div>
      {button ? (
        <Button className="custom-primary-button w-100" onClick={buttonEvent}>
          {buttonText}
        </Button>
      ) : null}
    </Card>
  );
};

export default FeatureCard;
