import { Card, Spinner } from "react-bootstrap";
import TopicDetailHeader from "./TopicDetailHeader";
import TopicDetailTreeProps from "./TopicDetailTree";
import TopicDetailTabs from "./TopicDetailTabs";
import { useTopicDetail } from "./useTopicDetail";
import "./styles.scss";
import { HEIGHT_PERCETN } from "../../../constants/components-const";
import { useTranslation } from "react-i18next";
import { CustomBreadcrumb, ErrorMessage } from "../../moleculas";
import { HOME, STATISTICS } from "../../../constants/routes";

const TopicDetail = () => {
  const {
    indicatorSelected,
    category,
    subcategories,
    selectIndicator,
    loading,
    selectVariable,
    variableSelected,
    noData,
  } = useTopicDetail();
  const { t } = useTranslation("topic");
  // TopicDetailTreeProps on onClickOption={setIndicatorSelected}

  return (
    <div className="w-100 d-flex justify-content-md-start justify-content-lg-start flex-column">
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="w-75 align-items-left breadcrumb-height">
          <CustomBreadcrumb
            isWhite={false}
            breadcrumbs={[
              {
                name: t("home"),
                href: HOME,
              },
              { name: t("title"), href: STATISTICS },
              { name: category?.name },
            ]}
          />
          <h1 className="mt-3">{t("title")}</h1>
          <hr className="custom-divider" />
        </div>
        <div className="data-container mt-1">
          <div className="mb-3">
            <Card className="shadow">
              <TopicDetailHeader
                name={category?.name}
                iconSrc={category?.icon_url}
              />
              <hr className="w-100 no-margin" />
              <div
                style={{
                  height: `${screen.height * HEIGHT_PERCETN}px`,
                }}
                className="w-100 d-flex flex-column flex-md-row align-items-stretch"
              >
                <div className="topic-tree-container">
                  <TopicDetailTreeProps
                    variables={variableSelected}
                    onClickOption={selectVariable}
                    subcategories={subcategories}
                    topic={category}
                    onClickOptionVariable={selectIndicator}
                  />
                </div>
                <div className="topic-tree-tabs">
                  <div className="w-100 d-flex justify-content-center flex-row">
                    {loading && <Spinner animation="border" variant="dark" />}
                  </div>
                  {indicatorSelected &&
                    indicatorSelected.length > 0 &&
                    !loading &&
                    !noData && (
                      <TopicDetailTabs indicator={indicatorSelected} />
                    )}
                  {noData && (
                    <div className="w-100 d-flex justify-content-center flex-row">
                      <ErrorMessage message={t("noData")}></ErrorMessage>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicDetail;
