import React, { FC } from "react";
import { Table } from "react-bootstrap";
import { TableModes } from "../../../constants/components-types";
import "./styles.scss";

export interface CustomTableProps {
  headers: string[];
  rows: string[][];
  striped?: boolean;
  bordered?: boolean;
  hover?: boolean;
  borderless?: boolean;
  type?: TableModes;
}

const CustomTable: FC<CustomTableProps> = ({
  headers,
  rows,
  striped = true,
  bordered = true,
  hover = true,
  borderless = true,
}) => {
  return (
    <div>
      <Table
        borderless={borderless}
        striped={striped}
        bordered={bordered}
        hover={hover}
        className="normal-table"
      >
        <thead>
          <tr>
            {headers.map((header: any, index) => {
              return <th key={index}>{header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {rows &&
            rows.map((element: any, index: number) => {
              if (element.length > 1) {
                return (
                  <tr key={index}>
                    {element.map((x: any) => (
                      <td key={x}>{x}</td>
                    ))}
                  </tr>
                );
              }
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default CustomTable;
