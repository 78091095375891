import { RefObject, useEffect, useState } from "react";
import {
  BarChartColors,
  ChartProps,
  ChartType,
  Dimensions,
} from "../interfaces/commont";
import Barchart from "./barChart";
import Linechart from "./lineChart";
import Piechart from "./pieChart";

export interface ChartWrapperProps extends ChartProps {
  type: ChartType;
  ref: RefObject<HTMLElement>;
}

const useChartWrapper = ({
  type,
  ref,
  data,
  labels,
  colors,
}: ChartWrapperProps) => {
  const [dimensions, setDimensions] = useState<Dimensions>();
  const [chart, setChart] = useState<any>(null);
  const [show, setShow] = useState<boolean>(false);

  const setSize = () => {
    if (ref && ref.current) {
      const { offsetHeight, offsetWidth } = ref.current;

      if (offsetWidth && offsetHeight) {
        setDimensions({
          width: offsetWidth,
          height: offsetHeight,
        });
      }
    }
  };

  const barchartNode = () => (
    <Barchart
      data={data}
      dimensions={dimensions as Dimensions}
      colors={colors as BarChartColors}
      yField={labels.y}
      xField={labels.x}
    />
  );

  const linechartNode = () => (
    <Linechart
      data={data}
      dimensions={dimensions as Dimensions}
      yField={labels.y}
      xField={labels.x}
      colors={colors as string[]}
      groupField={labels.group}
    />
  );

  const piechartNode = () => (
    <Piechart
      data={data}
      colors={colors as string[]}
      labelField={labels.x}
      valueField={labels.y}
      dimensions={dimensions as Dimensions}
    />
  );

  const charts = {
    [ChartType.Bar]: barchartNode(),
    [ChartType.Line]: linechartNode(),
    [ChartType.Pie]: piechartNode(),
  };

  const handleResize = () => {
    setSize();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setSize();
  }, [show]);

  useEffect(() => {
    if (data && dimensions) {
      setChart(charts[type]);
    }
  }, [data, dimensions]);

  return { chart, setShow };
};

export default useChartWrapper;
