import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIndicatorsFailure,
  getIndicatorsStart,
  getIndicatorsSuccess,
} from "../reducers/indicatorReducer/actions";
import IndicatorsService from "../services/api/indicatorsService";
import { Category } from "../types/api/indicators";

const useIndicators = () => {
  const { loading, indicators } = useSelector((state: any) => state.indicators);
  const dispatch = useDispatch();
  const service = new IndicatorsService();

  useEffect(() => {
    if (!indicators || indicators.length === 0) {
      dispatch(getIndicatorsStart());
      service
        .getCategories()
        .then((response: Category[]) => {
          dispatch(getIndicatorsSuccess(response));
        })
        .catch((error) => {
          dispatch(getIndicatorsFailure(error));
        });
    }
  }, []);

  return {
    loading,
    indicators,
  };
};

export default useIndicators;
