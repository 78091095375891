export const HOME = "/";
export const CONTACT = "/contacto";
export const ABOUT = "/acerca";
export const STATISTICS = "/datos";
export const STATISTICS_ONE = "/datos/:id";
export const DOCS = "/documentacion";
export const DOCS_ID = "/documentacion/:id";
export const DIRECTORY = "/contacto/directorio";
export const ENVIRONMENTAL = "/contacto/ambiental";
export const QUESTIONS = "/faq";
