export const TABLE_NORMAL = "normal-table";
export const TABLE_CUSTOM = "custom-table";

// indicators resources

export const TABLE_TYPE = "CHART";
export const METHODOLOGICAL_SHEET = "METHODOLOGICAL_SHEET";
export const MAP = "MAP";
export const CHART_TYPE = "chart";
export const CHART_SUB_TYPE_BAR = "bar";
export const HEIGHT_PERCETN = 0.7;

// Api Const

export const API_ERROR = "ERROR_GENERIC_API";
