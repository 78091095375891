import React from "react";
import "./styles.scss";

export interface ContactInfoProp {
  icon: string;
  title: string;
  subtitle?: string;
}

const ContactInfoCard: React.FC<ContactInfoProp> = ({
  icon,
  title,
  subtitle = "",
}) => {
  return (
    <div className="d-flex flex-row align-items-center justify-content-start contact-info-card">
      <i className={`bi ${icon} contact-info-card-size contact-info-card-color`}></i>
      <div className="d-flex flex-column ms-3 justify-content-center align-items-start">
        {subtitle ? <div>{title}</div> : <strong>{title}</strong>}
        <strong>{subtitle}</strong>
      </div>
    </div>
  );
};

export default ContactInfoCard;
