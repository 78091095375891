import { CardGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { STATISTICS_BANNER } from "../../../../assets";
import { DATA } from "../../../../constants/commond";
import { HOME, STATISTICS } from "../../../../constants/routes";
import { FeatureCard, CustomBreadcrumb } from "../../../moleculas";
import "./styles.scss";

const StatisticsDescription = () => {
  const instructionCards = DATA.statisticsSection.instructionCards;
  const { t } = useTranslation("statistics");

  return (
    <div className="w-100 d-flex justify-content-center flex-column align-items-center pb-5 statistics-description">
      <div className="d-flex w-100 statistics-description-background justify-content-end">
        <img src={STATISTICS_BANNER} alt="statistics-banner" />
      </div>
      <div className="w-75 mb-5 d-flex flex-column align-items-center statistics-description-text">
        <div className="w-100 align-items-left">
          <CustomBreadcrumb
            isWhite={false}
            breadcrumbs={[
              {
                name: t("home"),
                href: HOME,
              },
              { name: t("title"), href: STATISTICS },
            ]}
          />
          <div className="d-flex flex-column col-sm col-md-12 mb-2">
            <h1>{t("title")}</h1>
            <hr className="custom-divider" />
            <div className="col-lg-5 col-12">
              <div className="mb-3">{t("description1")}</div>
              {t("description2")}
            </div>
          </div>
        </div>
      </div>
      <div className="w-75 d-flex flex-column">
        <h3 className="statistics-description-text-width mb-4">
          {t("instructions.title")}
        </h3>
        <div className="d-flex flex-row">
          <CardGroup className="d-flex flex-lg-row flex-column statistics-description-shadow">
            {instructionCards.map((card, key) => (
              <FeatureCard
                key={key}
                icon={card.icon}
                txt={t(`instructions.${card.translate}.description`)}
                title={t(`instructions.${card.translate}.title`)}
                shadow={" "}
              />
            ))}
          </CardGroup>
        </div>
      </div>
    </div>
  );
};

export default StatisticsDescription;
