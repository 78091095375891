import { FC } from "react";
import { useTranslation } from "react-i18next";
import FeatureCardSection from "../../Home/FeatureCardSection";

interface StatisticsCategoriesProps {
  categories: any[];
}

const StatisticsCategories: FC<StatisticsCategoriesProps> = ({
  categories,
}) => {
  const { t } = useTranslation("statistics");
  return (
    <div className="w-100 my-3">
      <div className="w-100 d-flex my-4 flex-column align-items-start align-items-md-center">
        <h1 className="text-center">{t("categorySection")}</h1>
        <hr className="custom-divider" />
      </div>
      <FeatureCardSection cardList={categories} isDark={true} />
    </div>
  );
};

export default StatisticsCategories;
