import React, { useState } from "react";
import { Accordion, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomAccordionItem from "../../../moleculas/CustomAccordionItem";
import "./styles.scss";

export interface OtherMeansInfoProp {
  data: any[];
  type: "directory" | "environmental";
}

const OtherMeansInfo: React.FC<OtherMeansInfoProp> = ({ data, type }) => {
  const [selection, setSelection] = useState(0);
  const [selectionName, setSelectionName] = useState("item1");
  const { t } = useTranslation();

  const setItemSelection = (key: number, value: any) => {
    setSelection(key);
    setSelectionName(value.translate);
  };

  return (
    <div className="w-100 d-flex flex-column my-5">
      <div className="w-100 d-flex flex-column flex-lg-row">
        <div className="col-lg-4 me-lg-5 mb-3">
          <ListGroup defaultActiveKey="0" className="shadow-sm">
            {data.map((item, key) => (
              <li
                id="item"
                key={key}
                onClick={() => setItemSelection(key, item)}
                className={`list-group-item clickable ${
                  selection === key ? "active" : ""
                }`}
              >
                <div className="d-flex flex-row justify-content-between">
                  <div>
                    {type === "environmental"
                      ? t(`list.${item.translate}.section`, { ns: "platform" })
                      : t(`list.${item.translate}.name`, { ns: "directory" })}
                  </div>
                  {selection === key ? (
                    <i className={`bi bi-arrow-right`}></i>
                  ) : null}
                </div>
              </li>
            ))}
          </ListGroup>
        </div>
        <div className="col-lg-8">
          {type === "environmental" ? (
            <div className="d-flex flex-column mb-3 col-lg-8 col-12 mt-4">
              <h5>
                {t(`list.${data[selection].translate}.section`, {
                  ns: "platform",
                })}
              </h5>
              <div className="body-1 color">
                {t(`list.${data[selection].translate}.description`, {
                  ns: "platform",
                })}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <Accordion defaultActiveKey="0">
            {data[selection].subsections.map((info: any, index: number) => (
              <div className="mb-3" key={index}>
                <CustomAccordionItem
                  accordionKey={index.toString()}
                  title={t(`list.${selectionName}.subItems.${info.translate}`, {
                    ns: type === "environmental" ? "platform" : "directory",
                  })}
                >
                  {type === "directory" ? (
                    <div className="d-flex flex-column">
                      {info.website && (
                        <div>
                          <h5 className="body-2">
                            {t("accordion.website", { ns: "directory" })}
                          </h5>
                          <p>
                            <a
                              className="body-1"
                              href={info.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {info.website}
                            </a>
                          </p>
                        </div>
                      )}
                      {info.phone && (
                        <div>
                          <h5 className="body-2">
                            {t("accordion.phone", { ns: "directory" })}
                          </h5>
                          <p className="body-1">{info.phone}</p>
                        </div>
                      )}
                      {info.email && (
                        <div>
                          <h5 className="body-2">E-mail</h5>
                          <p className="body-1">{info.email}</p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-column">
                      {info.links.map((url: string, index: number) => (
                        <p key={index}>
                          <a
                            className="body-1"
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {url}
                          </a>
                        </p>
                      ))}
                    </div>
                  )}
                </CustomAccordionItem>
              </div>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default OtherMeansInfo;
