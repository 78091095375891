import React, { FC } from "react";
import { Accordion, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { DATA } from "../../../../constants/commond";
import { QUESTIONS } from "../../../../constants/routes";
import { CustomAccordionItem } from "../../../moleculas";
import "./styles.scss";

export interface FrequentQuestionsProps {
  questionNumber?: number;
}

const FrequentQuestionsSection: FC<FrequentQuestionsProps> = ({
  questionNumber,
}) => {
  const { t } = useTranslation("questions");
  const navigate = useNavigate();

  const getQuestions = () => {
    const questions = DATA.FrequentQuestionsSection.questions;
    if (questionNumber) {
      return questions.slice(0, questionNumber);
    }
    return questions;
  };

  return (
    <div className="w-100 d-flex flex-column align-items-start align-items-md-center justify-content-center mb-5">
      <h1>{t("title")}</h1>
      <hr className="custom-divider" />
      <div className="w-100 mt-4">
        <Accordion defaultActiveKey="0">
          {getQuestions().map((question, index) => (
            <div className="mb-4" key={index}>
              <CustomAccordionItem
                accordionKey={index.toString()}
                title={t(`questions.${question}.title`)}
              >
                <p className="body-1">
                  {t(`questions.${question}.description`)}
                </p>
              </CustomAccordionItem>
            </div>
          ))}
        </Accordion>
        <div className="d-flex mt-3 justify-content-start justify-content-md-center">
          <Button
            className="custom-primary-button"
            onClick={() => {
              navigate(QUESTIONS);
            }}
          >
            {t("moreHelp")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FrequentQuestionsSection;
