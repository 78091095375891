import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DATA } from "../../../../constants/commond";
import { DOCS_ID } from "../../../../constants/routes";
import "./styles.scss";

const CardSection = () => {
  const history = useNavigate();
  const { t } = useTranslation("documentation");
  const cards = DATA.docsSection.docs;

  const onCardClick = (card: any) => {
    const doc = {
      docLength: card.docs.length === 1,
      description:
        JSON.parse(t(`docs.${card.translate}.description`)).length === 0,
    };

    if (doc.docLength && doc.description) {
      window.open(card.docs[0].link, "_blank");
    } else {
      history(DOCS_ID.replace(":id", card.id));
    }
  };

  return (
    <div className="w-100 mb-5">
      <div className="row">
        {cards.map((card, index) => (
          <div
            onClick={() => onCardClick(card)}
            key={index}
            className="col-lg-3 mb-3 col-sm-12 doc-card-container"
          >
            <Card className="w-100 h-100">
              <Card.Img className="img-fluid" variant="top" src={card.image} />
              <Card.Body>
                <Card.Text>{t(`docs.${card.translate}.title`)}</Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardSection;
