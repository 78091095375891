import { useState, useEffect } from "react";
import IndicatorsService from "../../../services/api/indicatorsService";
import {
  Category,
  SubCategorie,
  Indicator,
  Variable,
} from "../../../types/api/indicators";
import { useParams } from "react-router-dom";
import useIndicators from "../../../hooks/useIndicators";
interface Hook {
  indicatorSelected: Indicator[];
  setIndicatorSelected: any;
  category: Category;
  subcategories: SubCategorie[];
  selectIndicator: (id: string) => void;
  loading: boolean;
  selectVariable: any;
  variableSelected: Variable[];
  noData: boolean;
}

export function useTopicDetail(): Hook {
  const service = new IndicatorsService();
  const [indicatorSelected, setIndicatorSelected]: any | Indicator[] = useState(
    []
  );

  const [variableSelected, setVariableSelected]: any | Variable[] = useState(
    []
  );
  const [category, setCategory]: any | Category = useState();
  const [subcategories, setSubcategories]: any | SubCategorie[] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [noData, setNoData] = useState(false);
  const { indicators } = useIndicators();

  const getSubCategories = async (id: string) => {
    const subCategories = await service.getSubCategories(id);
    setSubcategories(subCategories);
  };

  const setValues = (values: any[], set: any) => {
    setNoData(!Array.isArray(values));
    if (!noData) {
      set(values);
    }
  };

  const selectVariable = async (id: string) => {
    setLoading(true);
    const variable = await service.getVariables(id);
    setValues(variable, setVariableSelected);
    setIndicatorSelected([]);
    setLoading(false);
  };

  const selectIndicator = async (id: string) => {
    setLoading(true);
    const indicators = await service.getIndicators(id);
    setValues(indicators, setIndicatorSelected);
    setLoading(false);
  };

  useEffect(() => {
    if (indicators) {
      setCategory(indicators.find((x: any) => x.id === id));
    }
  }, [indicators]);

  useEffect(() => {
    setNoData(false);

    // eslint-disable-next-line
    getSubCategories(id!);
    setIndicatorSelected([]);
    setVariableSelected([]);
  }, [id]);

  return {
    indicatorSelected,
    setIndicatorSelected,
    category,
    subcategories,
    selectIndicator,
    loading,
    selectVariable,
    variableSelected,
    noData,
  };
}
