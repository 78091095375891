import React from "react";
import "./styles.scss";

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <div className="error-container">
      <i className="bi bi-exclamation-triangle icon"></i>
      <span className="ms-1"> {message}</span>
    </div>
  );
};

export default ErrorMessage;
