import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { languages } from "../assets";
import { availableLanguages, defaultLanguage } from "./languages";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = languages;

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
    },
    fallbackLng: defaultLanguage,
    lng: defaultLanguage,
    supportedLngs: availableLanguages.map((lng) => lng.value),
    debug: process.env.REACT_APP_PROD ? true : false,
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
