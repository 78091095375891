import React, { FC, useEffect, useState } from "react";
import { SINIA_ICON } from "../../../../assets";
import { Category } from "../../../../types/api/indicators";
import { FeatureCard } from "../../../moleculas";
import { useNavigate } from "react-router-dom";
import { STATISTICS_ONE } from "../../../../constants/routes";
import Spinner from "react-bootstrap/esm/Spinner";

export interface FeatureCardSectionProps {
  isDark: boolean;
  cardList?: Category[];
}

const FeatureCardSection: FC<FeatureCardSectionProps> = ({
  isDark,
  cardList = [],
}) => {
  // just for demo, remove after demo
  const [newList, setNewList]: any[] | Category[] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (cardList.length > 0) {
      const actualList: any = cardList;
      setNewList(actualList);
      setIsLoading(false);
    }
  }, [cardList]);
  const navigate = useNavigate();

  return (
    <div className="w-100">
      <div className="w-100 d-flex flex-row justify-content-center">
        {isLoading && (
          <Spinner animation="border" variant={isDark ? "dark" : "light"} />
        )}
      </div>
      <div className="row">
        {newList.map(
          (
            card: { id: string; name: string; icon_url: string },
            index: React.Key | null | undefined
          ) => (
            <div key={index} className="col-lg-3 mb-4 col-sm-12">
              <FeatureCard
                handleClick={() => {
                  if (card.id) {
                    navigate(STATISTICS_ONE.replace(":id", card.id));
                  }
                }}
                txt={card.name}
                icon={card.icon_url ? card.icon_url : SINIA_ICON}
                boldText={true}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default FeatureCardSection;
