import { AxiosRequestConfig } from "axios";
import HttpClient from "./httpService";

class ApiClient extends HttpClient {
  constructor() {
    super();
    this.initializeInterceptors({
      requestInterceptor: { onFulfilled: this.setRequest },
      responseInterceptor: {
        onRejected: this.handleUncaughtError,
      },
    });
  }

  private setRequest = async (
    config: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    config.headers = {
      "Content-type": "application/json",
      ...config.headers,
    };

    return config;
  };
}

export default ApiClient;
