/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import {
  BarChartColors,
  Dimensions,
  Margins,
  YDomain,
} from "../interfaces/commont";

export interface BarchartProp {
  data: any[];
  dimensions: Dimensions;
  xField: string;
  yField: string;
  diferenceYvalue?: number;
  yDomain?: YDomain;
  colors: BarChartColors;
}

const Barchart: React.FC<BarchartProp> = ({
  data,
  dimensions,
  xField,
  yField,
  yDomain,
  colors,
}) => {
  const defaultValues = {
    margin: 20,
    height: 200,
    width: 200,
    maxWidth: 50,
  };

  const [height, setHeight] = useState<number>(0);
  const [dimension, setDimension] = useState<Dimensions | null>(null);
  const [margin, setMargin] = useState<Margins>({
    top: defaultValues.margin,
    right: defaultValues.margin,
    bottom: defaultValues.margin,
    left: defaultValues.margin,
  });

  useEffect(() => {
    calculateDimensions();
  }, [dimensions]);

  useEffect(() => {
    if (dimension) {
      setChart();
    }
  }, [dimension, margin]);

  useEffect(() => {
    calculateMargins();
  }, [height]);

  function calculateDimensions() {
    const dimension = dimensions || defaultValues;
    const height =
      defaultValues.height > dimension.height
        ? defaultValues.height
        : dimension.height;

    const width =
      defaultValues.width > dimension.width
        ? defaultValues.width
        : dimension.width;

    setDimension({
      width,
      height,
    });
  }

  function calculateMargins() {
    const svg: any = d3.select("svg");
    const yLabel = svg.select(".y-axis").node().getBBox().width;
    const xLabel = svg.select(".x-axis").node().getBBox().height;
    setMargin({
      top: defaultValues.margin,
      right: defaultValues.margin,
      bottom: xLabel + defaultValues.margin,
      left: yLabel + defaultValues.margin,
    });
  }

  const setChart = () => {
    const svg: any = d3.select("svg");
    const height = dimension!.height;
    const width = dimension!.width;
    const maxWidth =
      data.length * (defaultValues.maxWidth + defaultValues.margin);

    const x = d3
      .scaleBand()
      .domain(data.map((d: any) => d[xField]))
      .rangeRound([margin.left, Math.min(width, maxWidth) - margin.right])
      .padding(0.1);

    const domainY = yDomain
      ? [yDomain.min, yDomain.max]
      : [0, d3.max(data, (d) => d[yField])];

    const y1 = d3
      .scaleLinear()
      .domain(domainY)
      .rangeRound([height - margin.bottom, margin.top]);

    const xAxis = (g: any) =>
      g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .style("color", colors.xColor)
        .call(d3.axisBottom(x).tickSizeOuter(0))
        .selectAll("text")
        .attr("class", "x-label");

    const y1Axis = (g: any) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .style("color", colors.yColor)
        .call(d3.axisLeft(y1).ticks(null, "s"))
        .selectAll("text")
        .attr("class", "y-label")
        .call((g: any) => g.select(".domain").remove())
        .call((g: any) =>
          g
            .append("text")
            .attr("x", -margin.left)
            .attr("y", 10)
            .attr("fill", "currentColor")
            .attr("text-anchor", "start")
        );

    svg.select(".x-axis").call(xAxis);
    svg.select(".y-axis").call(y1Axis);

    svg
      .select(".plot-area")
      .attr("fill", colors.barColor)
      .selectAll(".bar")
      .data(data)
      .join("rect")
      .attr("class", "bar")
      .attr("x", (d: any) => x(d[xField]))
      .attr("width", x.bandwidth())
      .attr("y", (d: any) => y1(d[yField]))
      .attr("height", (d: any) => y1(0) - y1(d[yField]));

    svg.selectAll(".x-label").each(function (this: any) {
      const labelWidth = this.getComputedTextLength();
      const barWidth = x.bandwidth();
      if (labelWidth > barWidth) {
        d3.select(this)
          .style("text-anchor", "end")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr("transform", "rotate(-65)");
      }
    });

    const fullHeight = y1(0) - y1(d3.max(data, (d) => d[yField]));
    setHeight(fullHeight);
  };

  return (
    <svg
      className="bar-chart"
      style={{
        height: dimension ? dimension.height : defaultValues.height,
        width: dimension ? dimension.width : defaultValues.width,
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );
};

export default Barchart;
