import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CONTACT_ICON_1, CONTACT_ICON_2 } from "../../../../assets";
import { DIRECTORY, ENVIRONMENTAL } from "../../../../constants/routes";
import { FeatureCard } from "../../../moleculas";

const OtherMeansSection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("otherMeans");

  return (
    <div className="w-100 d-flex mt-5 flex-column align-items-center">
      <div className="w-100 d-flex flex-column align-items-start align-items-md-center">
        <h2>{t("title")}</h2>
        <hr className="custom-divider" />
        <p className="mb-5 mt-3">{t("description")}</p>
      </div>
      <div className="d-md-flex flex-md-row d-flex flex-column justify-content-center">
        <div className="col-md-4 mb-4 col-sm-12">
          <FeatureCard
            txt={t("directory")}
            button={true}
            buttonText={t("directoryButton")}
            icon={CONTACT_ICON_1.default}
            centered={true}
            boldText={true}
            buttonEvent={() => {
              navigate(DIRECTORY);
            }}
          />
        </div>
        <div className="col-md-4 mb-4 col-sm-12 ms-md-3">
          <FeatureCard
            txt={t("platforms")}
            button={true}
            buttonText={t("platformsButton")}
            icon={CONTACT_ICON_2.default}
            centered={true}
            boldText={true}
            buttonEvent={() => {
              navigate(ENVIRONMENTAL);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OtherMeansSection;
