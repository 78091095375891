import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CR_LOGO } from "../../../assets";
import {
  ABOUT,
  CONTACT,
  DOCS,
  HOME,
  STATISTICS,
} from "../../../constants/routes";
import "./styles.scss";

const Footer = () => {
  const { t } = useTranslation("footer");
  const currentYear = new Date().getFullYear();

  return (
    <div className="w-100 d-flex flex-column align-items-center footer-container">
      <div className="w-100 d-flex flex-column flex-md-row p-5 align-items-between">
        <div className="col-sm-12 col-md-4 d-flex flex-column justify-content-start align-items-center">
          <img
            className="cr-logo img-fluid"
            alt="Logo Costa Rica"
            src={CR_LOGO}
          />
        </div>
        <div className="col-sm-12 col-md-4 d-flex flex-column justify-content-center align-items-center my-5 my-md-0">
          <div className="d-flex flex-column justify-content-center align-items-center align-items-md-start">
            <h3>SINIA</h3>
            <Nav.Link className="footer-link link" href={HOME}>
              {t("home")}
            </Nav.Link>

            <Nav.Link className="footer-link" href={ABOUT}>
              {t("about")}
            </Nav.Link>

            <Nav.Link className="footer-link" href={STATISTICS}>
              {t("statistics")}
            </Nav.Link>

            <Nav.Link className="footer-link" href={DOCS}>
              {t("documentation")}
            </Nav.Link>

            <Nav.Link className="footer-link" href={CONTACT}>
              {t("contact")}
            </Nav.Link>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 justify-content-center align-items-center align-items-md-start text-center text-md-start">
          <h3>{t("ceniga")}</h3>
          <div>
            <i className="bi bi-pin-map-fill"></i>
            <label className="m-2">{t("address")}</label>
          </div>
          <div>
            <i className="bi bi-envelope-fill"></i>
            <label className="m-2">sinia@minae.go.cr</label>
          </div>
          <div>
            <i className="bi bi-telephone-fill"></i>
            <label className="m-2">+506 2106-8626</label>
          </div>
          <div>
            <i className="bi bi-telephone-fill"></i>
            <label className="m-2">+506 2106-8627</label>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center footer-sub-container text-center p-3">
        <h6>{`${t("copyright")} ${currentYear}`}</h6>
      </div>
    </div>
  );
};

export default Footer;
