import React from "react";
import { useTranslation } from "react-i18next";
import { CONTACT, HOME } from "../../../../constants/routes";
import { CustomBreadcrumb } from "../../../moleculas";

export interface OtherMeansIntroProp {
  title: string;
  description: string;
}

const OtherMeansIntro: React.FC<OtherMeansIntroProp> = ({
  title,
  description,
}) => {
  const { t } = useTranslation("otherMeans");

  return (
    <div className="w-100 d-flex flex-column">
      <div className="w-100 d-flex align-items-left breadcrumb-height">
        <CustomBreadcrumb
          isWhite={false}
          breadcrumbs={[
            {
              name: t("home"),
              href: HOME,
            },
            { name: t("contact"), href: CONTACT },
            {
              name: title,
            },
          ]}
        />
      </div>
      <div className="col-lg-6 col-sm-6">
        <h1>{title}</h1>
        <hr className="custom-divider mt-3 mb-5" />
        <p>{description}</p>
      </div>
    </div>
  );
};

export default OtherMeansIntro;
