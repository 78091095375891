import React from "react";
import { useTranslation } from "react-i18next";
import { DATA } from "../../../../constants/commond";
import "./styles.scss";

const LogoSection = () => {
  const { t } = useTranslation("about");
  const logos = DATA.aboutSection.logo;

  return (
    <div className="w-100 d-flex flex-column align-items-start align-items-md-center justify-content-center mb-5">
      <h1>{t("logo.title")}</h1>
      <hr className="custom-divider" />
      <div className="w-100 mt-5 row">
        {logos.map((logo, index) => (
          <div
            key={index}
            className="col-sm-12 col-lg-3 d-flex flex-column align-items-left align-items-md-center"
          >
            <img className="custom-logo mb-3" src={logo.logo} />
            <p>{t(`logo.${logo.translate}`)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoSection;
