import { useTranslation } from "react-i18next";
import { ABOUT_BANNER } from "../../../../assets";
import { DATA } from "../../../../constants/commond";
import { ABOUT, HOME } from "../../../../constants/routes";
import { FeatureCard, CustomBreadcrumb } from "../../../moleculas";
import "./styles.scss";

const BannerSection = () => {
  const { t } = useTranslation("about");
  const bannerCards = DATA.aboutSection.bannerCard;

  return (
    <div className="w-100 d-flex justify-content-center flex-column align-items-center pb-5 about-banner-section">
      <div className="d-flex w-100 about-banner-section-background justify-content-end">
        <img src={ABOUT_BANNER} alt="statistics-banner" />
      </div>
      <div className="w-75 mb-lg-5 mb-0 d-flex flex-column align-items-center about-banner-section-text">
        <div className="w-100 align-items-left">
          <CustomBreadcrumb
            isWhite={false}
            breadcrumbs={[
              {
                name: t("home"),
                href: HOME,
              },
              { name: t("sectionName"), href: ABOUT },
            ]}
          />
          <div className="d-flex flex-column col-sm col-md-12 mb-2">
            <div className="col-lg-5 col-12">
              <h1 className="mb-3">{t("title")}</h1>
              <p>{t("description")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 w-75 mb-5">
        <div className="row">
          {bannerCards.map((cards, index) => (
            <div key={index} className="col-sm-6 col-lg-3 mb-sm-3">
              <FeatureCard
                shadow="shadow-sm"
                title={t(`cards.${cards.translate}.title`)}
                txt={t(`cards.${cards.translate}.description`)}
                icon={cards.logo}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
