export interface Margins {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface Dimensions {
  height: number;
  width: number;
}

export interface YDomain {
  min: number;
  max: number;
}

export interface ChartColors {
  xColor: string;
  yColor: string;
}

export interface BarChartColors extends ChartColors {
  barColor: string;
}

export interface ChartProps {
  data: any[];
  colors?: BarChartColors | string[];
  labels: AxisLabels;
}

export enum ChartType {
  Bar = "bar",
  Line = "line",
  Pie = "pie",
}

export interface AxisLabels {
  x: string;
  y: string;
  group?: string; // Label used for grouping data in linechart
}
