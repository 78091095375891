import { CONTACT_BACKGROUND } from "../../../assets";
import { CONTACT, HOME } from "../../../constants/routes";
import { CustomBreadcrumb } from "../../moleculas";
import { ContactSection, OtherMeansSection } from "../../organims";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const Contact = () => {
  const { t } = useTranslation("contact");

  useEffect(() => {
    document.title = `SINIA - ${t("title")}`;
  }, []);

  return (
    <div className="w-100 d-flex justify-content-md-start justify-content-lg-start flex-column">
      <div className="w-100">
        <div className="d-flex flex-column align-items-center justify-content-start">
          <div className="custom-blue-background"></div>
          <div className="custom-yellow-background"></div>
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center page-header-custom">
        <div className="w-75 align-items-left">
          <CustomBreadcrumb
            isWhite={true}
            breadcrumbs={[
              {
                name: t("home"),
                href: HOME,
              },
              { name: t("title"), href: CONTACT },
            ]}
          />
        </div>
        <div className="w-75 mb-2 align-items-left">
          <h1>{t("title")}</h1>
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="w-75 mt-3">
          <ContactSection />
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="w-75 mt-3">
          <OtherMeansSection />
        </div>
      </div>
      <div className="contact-image-background">
        <img src={CONTACT_BACKGROUND} alt="" />
      </div>
    </div>
  );
};

export default Contact;
