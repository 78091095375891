import CONTACT_EN from "./locale/en/contact.json";
import HEADER_EN from "./locale/en/header.json";
import FOOTER_EN from "./locale/en/footer.json";
import HOME_EN from "./locale/en/home.json";
import QUESTIONS_EN from "./locale/en/questions.json";
import CATEGORIES_EN from "./locale/en/categories.json";
import STATISTICS_EN from "./locale/en/statistics.json";
import ABOUT_EN from "./locale/en/about.json";
import OTHER_MEANS_EN from "./locale/en/otherMeans.json";
import DOCUMENTATION_EN from "./locale/en/documentation.json";
import PLATFORM_EN from "./locale/en/platform.json";
import DIRECTORY_EN from "./locale/en/directory.json";
import TOPIC_EN from "./locale/en/topic.json";
import ERROR_EN from "./locale/en/error.json";

import CONTACT_ES from "./locale/es/contact.json";
import HEADER_ES from "./locale/es/header.json";
import FOOTER_ES from "./locale/es/footer.json";
import HOME_ES from "./locale/es/home.json";
import QUESTIONS_ES from "./locale/es/questions.json";
import CATEGORIES_ES from "./locale/es/categories.json";
import STATISTICS_ES from "./locale/es/statistics.json";
import ABOUT_ES from "./locale/es/about.json";
import OTHER_MEANS_ES from "./locale/es/otherMeans.json";
import DOCUMENTATION_ES from "./locale/es/documentation.json";
import PLATFORM_ES from "./locale/es/platform.json";
import DIRECTORY_ES from "./locale/es/directory.json";
import TOPIC_ES from "./locale/es/topic.json";
import ERROR_ES from "./locale/es/error.json";

// languages
export const languages = {
  es: {
    contact: CONTACT_ES,
    header: HEADER_ES,
    footer: FOOTER_ES,
    home: HOME_ES,
    questions: QUESTIONS_ES,
    categories: CATEGORIES_ES,
    statistics: STATISTICS_ES,
    about: ABOUT_ES,
    otherMeans: OTHER_MEANS_ES,
    documentation: DOCUMENTATION_ES,
    platform: PLATFORM_ES,
    directory: DIRECTORY_ES,
    topic: TOPIC_ES,
    error: ERROR_ES,
  },
  en: {
    contact: CONTACT_EN,
    header: HEADER_EN,
    footer: FOOTER_EN,
    home: HOME_EN,
    questions: QUESTIONS_EN,
    categories: CATEGORIES_EN,
    statistics: STATISTICS_EN,
    about: ABOUT_EN,
    otherMeans: OTHER_MEANS_EN,
    documentation: DOCUMENTATION_EN,
    platform: PLATFORM_EN,
    directory: DIRECTORY_EN,
    topic: TOPIC_EN,
    error: ERROR_EN,
  },
};

/* eslint-disable @typescript-eslint/no-var-requires */
export const MINAE_ICON = require("./images/minae-icon.png");
export const SINIA_ICON = require("./images/sinia-icon.png");
export const HOME_BANNER = require("./images/home-banner.png");
export const SINIA_WORKS = require("./images/SINIAHowItWorks.png");
export const INTERACT_VIEWER = require("./images/interactViewer.png");
export const CONTACT_BACKGROUND = require("./images/map.png");
export const CR_LOGO = require("./images/CR-logo.png");
export const ABOUT_BANNER = require("./images/about-banner.jpeg");
export const STATISTICS_BANNER = require("./images/statistics-banner.png");
export const STATISTICS_SAHM = require("./images/statistics_sahm.png");

// about feature icons
export const CIRCLE_COLOR = require("./images/circle-color.svg");
export const FRAGMENT = require("./images/fragment.svg");
export const KEY_CONCEPTS = require("./images/key-concepts.svg");
export const LEAF_SINIA = require("./images/leaf-sinia.svg");

// about icons
export const ABOUT_ICON_1 = require("./images/about_1.svg");
export const ABOUT_ICON_2 = require("./images/about_2.svg");
export const ABOUT_ICON_3 = require("./images/about_3.svg");
export const ABOUT_ICON_4 = require("./images/about_4.svg");

// contact icons
export const CONTACT_ICON_1 = require("./images/contact_1.svg");
export const CONTACT_ICON_2 = require("./images/contact_2.svg");

// statistics icons
export const STATISTICS_ICON_1 = require("./images/statistics_1.svg");
export const STATISTICS_ICON_2 = require("./images/statistics_2.svg");
export const STATISTICS_ICON_3 = require("./images/statistics_3.svg");
export const STATISTICS_ICON_4 = require("./images/statistics_4.svg");

// docs imgs
export const DOC_1 = require("./images/doc_1.png");
export const DOC_2 = require("./images/doc_2.png");
export const DOC_3 = require("./images/doc_3.png");
export const DOC_4 = require("./images/doc_4.png");
export const DOC_5 = require("./images/doc_5.png");
export const DOC_6 = require("./images/doc_6.png");

// docs icon
export const DOC_DEFAULT = require("./images/doc_default.svg");
