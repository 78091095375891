import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ContactForm from "../ContactForm";
import ContactInfo from "../ContactInfo";

const ContactSection = () => {
  const { t } = useTranslation("contact");

  return (
    <Card className="shadow border-0 w-100 d-flex flex-column p-5">
      <h3 className="mb-3">{t("messageUs")}</h3>
      <div className="d-md-flex flex-md-row d-flex flex-column">
        <div className="w-100 me-md-5">
          <ContactForm />
        </div>
        <div className="w-100 ms-md-5 mt-md-3 mt-5">
          <ContactInfo />
        </div>
      </div>
    </Card>
  );
};

export default ContactSection;
