import React from "react";
//import "./styles.scss";

interface DocsViewDataProps {
  data: string[];
}

const DocsViewData: React.FC<DocsViewDataProps> = ({ data }) => {
  return (
    <div className="w-100 mb-5">
      {data.map((element, index) => (
        <p key={index}>{element}</p>
      ))}
    </div>
  );
};

export default DocsViewData;
