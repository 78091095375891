import {
  GET_INDICATORS_FAILURE,
  GET_INDICATORS_STARTED,
  GET_INDICATORS_SUCCESS,
} from "./../../constants/reduceActionTypes";

const defaultState = {
  indicators: [],
  loading: false,
  error: null,
};

const indicatorReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case GET_INDICATORS_STARTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_INDICATORS_SUCCESS: {
      return {
        ...state,
        loading: false,
        indicators: action.payload,
      };
    }
    case GET_INDICATORS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default indicatorReducer;
