import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Loader } from "./components/moleculas";
import { ScrollToTop } from "./components/organims";
import Header from "./components/organims/Header";
import useIndicators from "./hooks/useIndicators";
import AppRoutes from "./navigation/routes";

function App() {
  const { loading } = useIndicators();
  return (
    <Router>
      <ScrollToTop />
      {loading ? (
        <Loader />
      ) : (
        <Header>
          <AppRoutes />
        </Header>
      )}
    </Router>
  );
}

export default App;
