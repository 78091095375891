import { SINIA_ICON } from "../../../assets";
import "./styles.scss";

const Loader = () => (
  <div className="loader-container">
    <div className="spinner">
      <img src={SINIA_ICON} className="spinner-logo" />
    </div>
  </div>
);

export default Loader;
