/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import { Dimensions, Margins } from "../interfaces/commont";
import "./styles.scss";

export interface LinechartProps {
  data: any[];
  colors: string[];
  dimensions: Dimensions;
  groupField?: string;
  xField: string;
  yField: string;
}

const Linechart: React.FC<LinechartProps> = ({
  data,
  colors,
  dimensions,
  groupField,
  xField,
  yField,
}) => {
  const defaultValues = {
    margin: 10,
    height: 200,
    width: 200,
    stroke: 1.5,
    opacity: 1,
  };

  const [height, setHeight] = useState<number>(0);
  const [dimension, setDimension] = useState<Dimensions | null>(null);
  const [margin, setMargin] = useState<Margins>({
    top: defaultValues.margin,
    right: defaultValues.margin,
    bottom: defaultValues.margin,
    left: defaultValues.margin,
  });

  function calculateDimensions() {
    const getSize = (attr: "height" | "width") => {
      return defaultValues[attr] > dimensions![attr]
        ? defaultValues[attr]
        : dimensions![attr];
    };

    const width = getSize("width");
    const height = getSize("height");

    setDimension({
      width,
      height,
    });
  }

  function calculateMargins() {
    const svg: any = d3.select("svg");
    const yAxis = svg.select(".line-chart-y-axis").node();
    const xAxis = svg.select(".line-chart-x-axis").node();
    const xLabel = svg.select(".x-label").node();
    const yLabel = svg.select(".y-label").node();

    if (yAxis && xAxis && xLabel) {
      setMargin({
        top: yLabel.getBBox().height + defaultValues.margin,
        right: xLabel.getBBox().width + defaultValues.margin * 2,
        bottom: xAxis.getBBox().height + defaultValues.margin * 4,
        left: yAxis.getBBox().width + defaultValues.margin,
      });
    }
  }

  useEffect(() => {
    if (dimensions) {
      calculateDimensions();
    }
  }, [dimensions]);

  useEffect(() => {
    if (dimension) {
      setChart();
    }
  }, [dimension, margin]);

  useEffect(() => {
    if (height) {
      calculateMargins();
    }
  }, [height]);

  function setChart() {
    const svg: any = d3.select("svg");
    const height = dimension!.height - margin.top - margin.bottom;
    const width = dimension!.width - margin.left - margin.right;
    svg.select(".line-chart").selectAll("*").remove();

    svg
      .select(".line-chart")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const setSelection = (
      id: any,
      selected: boolean,
      opacity: number,
      stroke: number
    ) => {
      d3.selectAll(`.line-chart-line`).style("opacity", selected ? opacity : 1);
      d3.selectAll(`.line-chart-label`).style(
        "opacity",
        selected ? opacity : 1
      );
      d3.selectAll(`.line-chart-marker`).style(
        "fill-opacity",
        selected ? opacity : 1
      );
      d3.selectAll(`#tag${id}`)
        .attr("stroke-width", selected ? stroke : defaultValues.stroke)
        .style("fill-opacity", 1)
        .style("opacity", 1);
    };

    const sortedData = data.sort((a, b) => a[xField] - b[xField]);

    const colorArray = d3.scaleOrdinal().range(colors);

    const xScale = d3
      .scaleLinear()
      .domain([
        d3.min(data, (d: any) => d[xField]),
        d3.max(data, (d: any) => d[xField]),
      ])
      .range([0, width]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d: any) => d[yField])])
      .range([height, 0]);

    const xGrid = () => d3.axisBottom(xScale).ticks(5);

    const yGrid = () => d3.axisLeft(yScale).ticks(5);

    const lineGenerator = d3
      .line()
      .x((d: any) => xScale(d[xField]))
      .y((d: any) => yScale(d[yField]));

    svg
      .select(".line-chart")
      .append("g")
      .attr("class", "line-chart-x-axis")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale))
      .append("g")
      .attr("class", "x-label")
      .append("text")
      .attr("x", width + defaultValues.margin)
      .attr("y", margin.bottom / 3.5)
      .attr("fill", "black")
      .attr("text-anchor", "start")
      .text(`${xField} →`);

    svg
      .select(".line-chart")
      .append("g")
      .attr("class", "line-chart-y-axis")
      .call(d3.axisLeft(yScale))
      .append("g")
      .attr("class", "y-label")
      .append("text")
      .attr("x", -margin.left / 3)
      .attr("y", -margin.top / 4)
      .attr("fill", "black")
      .attr("text-anchor", "start")
      .text(`↑ ${yField}`);

    const lines = svg
      .select(".line-chart")
      .append("g")
      .attr("class", "line-chart-lines");

    const labels = svg
      .select(".line-chart")
      .append("g")
      .attr("class", "line-chart-labels");

    const markers = svg
      .select(".line-chart")
      .append("g")
      .attr("class", "line-chart-markers");

    const grid = svg
      .select(".line-chart")
      .append("g")
      .attr("class", "line-chart-grid");

    const tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .style("background", "#fff")
      .style("border-radius", "0.25em")
      .style("padding", "0.5em")
      .style("opacity", 0.8);

    grid
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(xGrid().tickSize(-height).tickFormat(null));

    grid.append("g").call(yGrid().tickSize(-width).tickFormat(null));
    const groups = d3.group(
      sortedData,
      (d: any) => d[groupField ? groupField : ""]
    );

    const labelPosition = width / groups.size;
    let i = 0;
    groups.forEach((d: any, key: any) => {
      d.id = i;

      lines
        .append("path")
        .data([d])
        .attr("class", "line-chart-line")
        .style("stroke", colorArray(key))
        .attr("id", `tag${d.id}`)
        .attr("d", lineGenerator)
        .on("mouseover", () => {
          setSelection(d.id, true, 0.1, 3);
        })
        .on("mouseout", () => {
          setSelection(
            d.id,
            false,
            defaultValues.opacity,
            defaultValues.stroke
          );
        });

      labels
        .append("text")
        .attr("x", labelPosition / groups.size + i * labelPosition)
        .attr("y", height + margin.top * 2)
        .attr("class", "line-chart-label")
        .attr("id", `tag${d.id}`)
        .style("fill", colorArray(key))
        .text(key)
        .on("mouseover", () => {
          setSelection(d.id, true, 0.1, 3);
        })
        .on("mouseout", () => {
          setSelection(
            d.id,
            false,
            defaultValues.opacity,
            defaultValues.stroke
          );
        });

      d.forEach((value: any) => {
        markers
          .append("circle")
          .attr("class", "line-chart-marker")
          .attr("id", `tag${d.id}`)
          .attr("r", 3)
          .attr("cx", xScale(value[xField]))
          .attr("cy", yScale(value[yField]))
          .attr("fill", colorArray(key))
          .on("mouseover", (event: any) => {
            setSelection(d.id, true, 0.1, 3);
            d3.select(event.currentTarget).attr("r", 6);
            tooltip
              .style("visibility", "visible")
              .html(
                `${xField}: ${value[xField]}<br/>${yField}: ${value[yField]}`
              )
              .style("left", event.pageX + 3 + "px")
              .style("top", event.pageY + 5 + "px");
          })
          .on("mouseout", (event: any) => {
            d3.select(event.currentTarget).attr("r", 3);
            setSelection(
              d.id,
              false,
              defaultValues.opacity,
              defaultValues.stroke
            );
            tooltip.style("visibility", "hidden");
          });
      });

      i++;
    });

    // get d3 full height of chart
    const chartHeight = svg.node()?.getBBox().height;
    setHeight(chartHeight ? chartHeight : defaultValues.height);
  }

  return (
    <svg
      style={{
        height: dimension ? dimension.height : defaultValues.height,
        width: dimension ? dimension.width : defaultValues.width,
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="line-chart" />
    </svg>
  );
};

export default Linechart;
