import { useLocation } from "react-router-dom";
import { DATA } from "../../../constants/commond";

interface Hook {
  data: any;
}

export function useDocsView(): Hook {
  const location = useLocation();
  const id = location.pathname.split("/").slice(-1)[0];
  const document = DATA.docsSection.docs.find((x) => x.id === id);

  return { data: document };
}
