import React from "react";
import { Card } from "react-bootstrap";
import "./styles.scss";

export interface FileCardProp {
  icon: string;
  column?: boolean;
  card: {
    link: string;
    txt: string;
  };
}

const FileCard: React.FC<FileCardProp> = ({ card, icon, column }) => {
  return (
    <Card
      className={`d-flex p-3 ${
        column ? "flex-column" : "flex-row"
      } align-items-center clickable`}
      onClick={() => window.open(card.link, "_blank")}
    >
      <img className="file-card-img me-3" src={icon} />
      <h6>{card.txt}</h6>
    </Card>
  );
};

export default FileCard;
