import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EmailService from "../../../../services/api/emailService";

interface FormFields {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export function useContactForm() {
  const service = new EmailService();
  const { t } = useTranslation("error");
  const emailRegex = /^[^\s@]+@[^\s@]+(\.[^\s@]+)?$/;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = (values: any) => {
    const errors: any = {};
    Object.keys(initialValues).map((value: string) => {
      if (!values[value as keyof FormFields]) {
        errors[value] = t(`${value}.required`);
      } else if (value === "email" && !emailRegex.test(values[value])) {
        errors[value] = t("email.invalid");
      }
    });
    return errors;
  };

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    const payload = {
      contact_name: values.name,
      cc_email: values.email,
      subject: values.subject,
      body: values.message,
    };

    service
      .postNotification(payload)
      .then((res) => {
        if (res) {
          formik.resetForm();
          formik.setStatus({ success: true });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const initialValues: FormFields = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validate,
    validateOnBlur: true,
  });

  return {
    formik,
    isSubmitting,
  };
}
