import React, { useEffect } from "react";
import { DOCS, HOME } from "../../../constants/routes";
import { DocsViewData } from "../../organims";
import { useDocsView } from "./useDocsView";
import "./styles.scss";
import { FileCard, CustomBreadcrumb } from "../../moleculas";
import { DOC_DEFAULT } from "../../../assets";
import { useTranslation } from "react-i18next";

const DocsView = () => {
  const { data } = useDocsView();
  const { t } = useTranslation("documentation");
  const document = {
    title: t(`docs.${data.translate}.title`),
    data: JSON.parse(t(`docs.${data.translate}.description`)),
    docs: data.docs,
  };

  useEffect(() => {
    document.title = `SINIA - ${t("title")}`;
  }, []);

  return (
    <div className="w-100 d-flex justify-content-md-start justify-content-lg-start flex-column">
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="w-75 align-items-left breadcrumb-height">
          <CustomBreadcrumb
            isWhite={false}
            breadcrumbs={[
              {
                name: t("home"),
                href: HOME,
              },
              { name: t("title"), href: DOCS },
              { name: document.title },
            ]}
          />
        </div>
        <div className="w-75 mb-5 align-items-left">
          <h1>{document.title}</h1>
          <hr className="custom-divider" />
          {document.data.length > 0 ? (
            <div className="flex-md-row d-flex flex-column">
              <div className="w-100 me-5">
                <DocsViewData data={document.data} />
              </div>
              <div className="col-lg-4 ms-lg-5">
                <h5>{t("downloads")}</h5>
                {document.docs.map((info: any, index: any) => (
                  <div className="my-3" key={index}>
                    <FileCard card={info} icon={DOC_DEFAULT.default} />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column">
              <h5>{t("downloads")}</h5>
              <div className="d-flex flex-row flex-wrap">
                {document.docs.map((info: any, index: any) => (
                  <div
                    className="me-lg-2 me-sm-0 mb-2 col-lg-2 col-sm-12 d-flex  justify-content-stretch"
                    key={index}
                  >
                    <FileCard
                      card={info}
                      icon={DOC_DEFAULT.default}
                      column={true}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocsView;
