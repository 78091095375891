import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { STATISTICS_SAHM } from "../../../../assets";
import { DOCS_ID } from "../../../../constants/routes";
import "./styles.scss";
const StatisticsSAHM = () => {
  const { t } = useTranslation("statistics");
  const navigate = useNavigate();
  const DOC_ID = "6";

  return (
    <div className="w-100 d-flex flex-column align-items-center statistics-sahm py-5">
      <div className="w-75 d-flex flex-lg-row flex-column mb-5 justify-content-center align-items-center statistics-sahm">
        <div className="w-100 d-flex flex-column mb-3 me-3 align-items-start">
          <h1>{t("sahm")}</h1>
          <hr className="custom-divider" />
          <p>{t("sahmDescription1")}</p>
          <p>{t("sahmDescription2")}</p>
          <Button
            onClick={() => navigate(DOCS_ID.replace(":id", DOC_ID))}
            className="custom-primary-button w-75 my-4"
          >
            {t("methodologyGuides")}
          </Button>
          <Button className="custom-primary-button w-75 my-4">
            {t("sahmButton")}
          </Button>
        </div>
        <div className="w-100 statistics-sahm-img ms-3">
          <img src={STATISTICS_SAHM} alt="statistics_sahm" />
        </div>
      </div>
    </div>
  );
};

export default StatisticsSAHM;
