import React from "react";
import { Breadcrumb } from "react-bootstrap";
import "./styles.scss";

export interface CustomBreadcrumbProp {
  isWhite: boolean;
  breadcrumbs: {
    name: string;
    href?: string;
  }[];
}

const CustomBreadcrumb: React.FC<CustomBreadcrumbProp> = ({
  isWhite,
  breadcrumbs,
}) => {
  return (
    <Breadcrumb
      className={`d-flex flex-row ${
        isWhite ? "breadcrumb-white" : "breadcrumb-black"
      }`}
    >
      {breadcrumbs.map((breadcrumb, key) => (
        <Breadcrumb.Item
          className="breadcrumb-item"
          href={breadcrumb.href}
          key={key}
        >
          {breadcrumb.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
