import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { CONTACT_ICON_2 } from "../../../assets";
import { DATA } from "../../../constants/commond";
import { ENVIRONMENTAL } from "../../../constants/routes";
import OtherMeansFooter from "../../organims/OtherMeansView/OtherMeansFooter";
import OtherMeansInfo from "../../organims/OtherMeansView/OtherMeansInfo";
import OtherMeansIntro from "../../organims/OtherMeansView/OtherMeansIntro";

const Directory = () => {
  const navigate = useNavigate();
  const directory = DATA.directory;
  const { t } = useTranslation("directory");

  useEffect(() => {
    document.title = `SINIA - ${t("title", { ns: "contact" })}`;
  }, []);

  return (
    <div className="w-100 d-flex justify-content-md-start justify-content-lg-start flex-column">
      <div className="w-100 d-flex flex-column align-items-center background-color">
        <div className="w-75 align-items-left">
          <OtherMeansIntro title={t("title")} description={t("description")} />
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center background-color">
        <div className="w-75 align-items-left">
          <OtherMeansInfo data={directory} type="directory" />
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="w-75 align-items-left">
          <OtherMeansFooter
            txt={t("platforms", { ns: "otherMeans" })}
            buttonText={t("platformsButton", { ns: "otherMeans" })}
            icon={CONTACT_ICON_2.default}
            buttonEvent={() => {
              navigate(ENVIRONMENTAL);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Directory;
