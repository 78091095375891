import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DOCS, HOME } from "../../../constants/routes";
import CustomBreadcrumb from "../../moleculas/CustomBreadcrumb";
import { CardSection } from "../../organims";

const Docs = () => {
  const { t } = useTranslation("documentation");

  useEffect(() => {
    document.title = `SINIA - ${t("title")}`;
  }, []);

  return (
    <div className="w-100 d-flex justify-content-md-start justify-content-lg-start flex-column">
      <div className="w-100">
        <div className="d-flex flex-column align-items-center justify-content-start">
          <div className="custom-blue-background"></div>
          <div className="custom-yellow-background"></div>
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center page-header-custom">
        <div className="w-75 align-items-left">
          <CustomBreadcrumb
            isWhite={true}
            breadcrumbs={[
              {
                name: t("home"),
                href: HOME,
              },
              { name: t("title"), href: DOCS },
            ]}
          />
        </div>
        <div className="d-flex w-75 mb-2 align-items-left">
          <div className="col-lg-6 col-12">
            <h1>{t("title")}</h1>
            <p>{t("description")}</p>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="w-75 mt-3">
          <CardSection />
        </div>
      </div>
    </div>
  );
};

export default Docs;
