import React from "react";
import { useTranslation } from "react-i18next";
import { SINIA_WORKS } from "../../../../assets";
import "./styles.scss";

const HowItWorksSection = () => {
  const { t } = useTranslation("home");

  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-5 mb-5">
      <h1>{t("how")}</h1>
      <hr className="custom-divider" />
      <img
        alt="Como funciona el SINIA"
        className="img-fluid"
        src={SINIA_WORKS}
      ></img>
    </div>
  );
};

export default HowItWorksSection;
