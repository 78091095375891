/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import { Dimensions, Margins } from "../interfaces/commont";
import "./styles.scss";

export interface PieChartProps {
  data: any[];
  colors: string[];
  labelField: string;
  valueField: string;
  dimensions: Dimensions;
}

const Piechart: React.FC<PieChartProps> = ({
  data,
  colors,
  valueField,
  labelField,
  dimensions,
}) => {
  const defaultValues = {
    margin: 10,
    height: 200,
    width: 200,
  };

  const [margin, setMargin] = useState<Margins>({
    top: defaultValues.margin,
    bottom: defaultValues.margin,
    left: defaultValues.margin,
    right: defaultValues.margin,
  });
  const [radius, setRadius] = useState<number>(0);
  const [dimension, setDimension] = useState<Dimensions | null>(null);

  useEffect(() => {
    if (dimensions) {
      calculateDimensions();
    }
  }, [dimensions]);

  useEffect(() => {
    if (dimension) {
      calculateMargins();
    }
  }, [dimension]);

  useEffect(() => {
    if (margin) {
      setChart();
    }
  }, [margin]);

  function calculateMargins() {
    const width = dimension ? dimension.width : defaultValues.width;
    const svg: any = d3.select("svg");
    const chart = svg.select(".pie-chart").node();

    if (chart) {
      const chartWidth = chart.getBBox().width;
      const marginX = (width - chartWidth) / 2;
      setMargin({
        top: defaultValues.margin,
        bottom: defaultValues.margin,
        left: marginX!,
        right: marginX!,
      });
    }
  }

  function calculateDimensions() {
    const getSize = (attr: "height" | "width") => {
      return defaultValues[attr] > dimensions![attr]
        ? defaultValues[attr]
        : dimensions![attr];
    };

    const width = getSize("width");
    const height = getSize("height");

    const size = height > width ? width : height;
    setRadius(size / 3);
    setDimension({
      width,
      height,
    });
  }

  const midAngle = (d: any) => {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
  };

  const setChart = () => {
    const svg: any = d3.select("svg");
    const width = dimension ? dimension.width : defaultValues.width;
    const height =
      (dimension ? dimension.height : defaultValues.height) +
      margin.top +
      margin.bottom;
    svg.select(".pie-chart").selectAll("*").remove();

    svg
      .select(".pie-chart")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);
    const fillOpacity = 0.2;

    const colorArray = d3.scaleOrdinal().range(colors);

    const pie = d3.pie().value((d: any) => d[valueField]);

    const pieData = pie(data);

    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    const labelOffset = 1.05;
    const labelRadius = radius * 1.1;
    const labelArc = d3.arc().innerRadius(labelRadius).outerRadius(labelRadius);

    const lineRadius = radius;
    const lineArc = d3.arc().innerRadius(lineRadius).outerRadius(lineRadius);

    const setSelection = (id: any, selected: boolean) => {
      d3.selectAll(".pie-chart-polyline").style(
        "opacity",
        selected ? fillOpacity : 1
      );
      d3.selectAll(".pie-chart-label").style(
        "opacity",
        selected ? fillOpacity : 1
      );
      d3.selectAll(".pie-chart-slice").style(
        "opacity",
        selected ? fillOpacity : 1
      );
      d3.selectAll(`#tag_${id}`)
        .style("opacity", 1)
        .style("stroke-width", selected ? 1.5 : 1);
    };

    const onMouseMove = (event: any, d: any) => {
      tooltip
        .style("opacity", 0.8)
        .style("left", event.pageX + 3 + "px")
        .style("top", event.pageY + 5 + "px")
        .html(`${d.data[labelField]}: ${d.data[valueField]}`);
    };

    const showTooltip = (event: any, d: any) => {
      setSelection(d.index, true);
      return tooltip.style("visibility", "visible");
    };

    const hideTooltip = (event: any, d: any) => {
      setSelection(d.index, false);
      return tooltip.style("visibility", "hidden");
    };

    const tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .style("background", "#fff")
      .style("border-radius", "0.25em")
      .style("padding", "0.5em");

    svg
      .select(".pie-chart")
      .selectAll("slices")
      .data(pieData)
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", colorArray)
      .attr("class", "pie-chart-slice")
      .attr("stroke", "white")
      .attr("stroke-width", "0.1em")
      .attr("id", (d: any) => `tag_${d.index}`)
      .on("mouseover", showTooltip)
      .on("mousemove", onMouseMove)
      .on("mouseout", hideTooltip);

    svg
      .select(".pie-chart")
      .selectAll("polylines")
      .data(pieData)
      .enter()
      .append("polyline")
      .attr("class", "pie-chart-polyline")
      .attr("stroke", colorArray)
      .attr("stroke-width", 1)
      .attr("fill", "none")
      .attr("id", (d: any) => `tag_${d.index}`)
      .attr("points", (d: any) => {
        const labelPos = labelArc.centroid(d);
        labelPos[0] = labelRadius * (midAngle(d) < Math.PI ? 1 : -1);
        return [lineArc.centroid(d), labelArc.centroid(d), labelPos];
      })
      .on("mouseover", showTooltip)
      .on("mousemove", onMouseMove)
      .on("mouseout", hideTooltip);

    svg
      .select(".pie-chart")
      .selectAll("labels")
      .data(pieData)
      .enter()
      .append("text")
      .attr("class", "pie-chart-label")
      .attr("id", (d: any) => `tag_${d.index}`)
      .text((d: any) => {
        return d.data[labelField];
      })
      .attr("transform", (d: any) => {
        const pos = labelArc.centroid(d);
        pos[0] = labelRadius * labelOffset * (midAngle(d) < Math.PI ? 1 : -1);
        return `translate(${pos})`;
      })
      .style("text-anchor", (d: any) => {
        return midAngle(d) < Math.PI ? "start" : "end";
      })
      .on("mouseover", showTooltip)
      .on("mousemove", onMouseMove)
      .on("mouseout", hideTooltip);
  };

  return (
    <svg
      style={{
        height: dimension ? dimension.height : defaultValues.height,
        width: dimension ? dimension.width : defaultValues.width,
      }}
    >
      <g className="pie-chart" />
    </svg>
  );
};

export default Piechart;
