import React from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import "./styles.scss";

export interface CustomAccordionItemProp {
  title: string;
  accordionKey: string;
  callback?: any;
}

const CustomAccordionItem: React.FC<CustomAccordionItemProp> = ({
  accordionKey,
  title,
  callback,
  children,
}) => {
  const onClick = useAccordionButton(
    accordionKey,
    () => callback && callback(accordionKey)
  );

  return (
    <Card className="py-3 px-4 shadow-sm">
      <div
        onClick={onClick}
        className="w-100 d-flex flex-row justify-content-between align-items-center clickable"
      >
        <h6 className="my-0">{title}</h6>
        <i className="bi bi-plus"></i>
      </div>
      <Accordion.Collapse eventKey={accordionKey}>
        <div className="d-flex justify-content-start col-lg-10 col-12">
          {children}
        </div>
      </Accordion.Collapse>
    </Card>
  );
};

export default CustomAccordionItem;
