import React from "react";
import { useTranslation } from "react-i18next";
import ContactInfoCard from "../../../moleculas/ContactInfoCard";
import "./styles.scss";

const ContactInfo = () => {
  const { t } = useTranslation("contact");

  return (
    <div className="w-100">
      <div className="mb-5">
        <ContactInfoCard
          title={t("addressTitle")}
          subtitle={t("address")}
          icon="bi-geo-alt"
        ></ContactInfoCard>
      </div>
      <div className="mb-5">
        <ContactInfoCard
          title={t("hoursTitle")}
          subtitle={t("hours")}
          icon="bi-clock"
        ></ContactInfoCard>
      </div>
      <div className="mb-5">
        <ContactInfoCard
          title="sinia@minae.go.cr"
          icon="bi-envelope"
        ></ContactInfoCard>
      </div>
      <div className="mb-5">
        <ContactInfoCard
          title="+506 2106-8626"
          icon="bi-telephone"
        ></ContactInfoCard>
      </div>
      <div className="mb-5">
        <ContactInfoCard
          title="+506 2106-8627"
          icon="bi-telephone"
        ></ContactInfoCard>
      </div>
      <div className="d-flex flex-row">
        <a href="" target="_blank" className="me-3">
          <i className="bi bi-facebook contact-info-icon-size"></i>
        </a>
        <a href="#" target="_blank">
          <i className="bi bi-twitter contact-info-icon-size"></i>
        </a>
      </div>
    </div>
  );
};

export default ContactInfo;
