import {
  ABOUT,
  CONTACT,
  HOME,
  STATISTICS,
  DOCS,
  DOCS_ID,
  DIRECTORY,
  ENVIRONMENTAL,
  STATISTICS_ONE,
  QUESTIONS,
} from "../constants/routes";
import { Routes, Route } from "react-router-dom";
import {
  About,
  Home,
  Contact,
  Statistics,
  Docs,
  DocsView,
  FAQ,
} from "../components/pages";
import TopicDetail from "../components/pages/TopicDetail";
import Directory from "../components/pages/Directory";
import EnvironmentalInfo from "../components/pages/EnvironmentalInfo";

const AppRoutes = () => (
  <Routes>
    <Route element={<Home />} path={HOME} />
    <Route element={<Contact />} path={CONTACT} />
    <Route element={<About />} path={ABOUT} />
    <Route element={<Statistics />} path={STATISTICS} />
    <Route element={<TopicDetail />} path={STATISTICS_ONE} />
    <Route element={<Docs />} path={DOCS} />
    <Route element={<DocsView />} path={DOCS_ID} />
    <Route element={<Directory />} path={DIRECTORY} />
    <Route element={<EnvironmentalInfo />} path={ENVIRONMENTAL} />
    <Route element={<FAQ />} path={QUESTIONS} />
  </Routes>
);

export default AppRoutes;
