import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  TopicDetail as TopicDetailContainer,
  TopicDetailCategories,
} from "../../organims";
import "./styles.scss";

const TopicDetail = () => {
  const { t } = useTranslation("statistics");
  useEffect(() => {
    document.title = `SINIA - ${t("topic")}`;
  }, []);

  return (
    <div className="w-100">
      <TopicDetailContainer />
      <TopicDetailCategories />
    </div>
  );
};

export default TopicDetail;
