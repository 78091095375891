import React from "react";
import { useTranslation } from "react-i18next";
import { FeatureCard } from "../../../moleculas";

export interface OtherMeansFooterProp {
  txt: string;
  icon: string;
  buttonEvent: any;
  buttonText: string;
}

const OtherMeansFooter: React.FC<OtherMeansFooterProp> = ({
  txt,
  icon,
  buttonEvent,
  buttonText,
}) => {
  const { t } = useTranslation("otherMeans");
  return (
    <div className="d-md-flex flex-md-row d-flex flex-column justify-content-center py-5">
      <div className="col-md-4 mb-4 col-sm-12 me-md-3">
        <h1>{t("title")}</h1>
        <hr className="custom-divider" />
        <p>{t("description")}</p>
      </div>
      <div className="col-md-4 mb-4 col-sm-12 ms-md-3">
        <FeatureCard
          txt={txt}
          button={true}
          buttonText={buttonText}
          icon={icon}
          centered={true}
          boldText={true}
          buttonEvent={buttonEvent}
        />
      </div>
    </div>
  );
};

export default OtherMeansFooter;
