import React, { FC, useRef } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useChartWrapper from "../../../../D3Module/components/useChartWrapper";
import { ChartType } from "../../../../D3Module/interfaces/commont";
import { Indicator } from "../../../../types/api/indicators";
import CustomTable from "../../Table";
import "./styles.scss";
import { useTopicDetailTabs } from "./useTopicDetailTabs";

export interface TopicDetailTabsProps {
  indicator: Indicator[];
}

const TopicDetailTabs: FC<TopicDetailTabsProps> = ({ indicator }) => {
  const {
    showTable,
    header,
    rows,
    showMethodologicalSheet,
    methodologicalSheet,
    title,
    mapFrame,
    showMapFrame,
    chartData,
  } = useTopicDetailTabs(indicator);
  const { t } = useTranslation("topic");
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const chartContainer = useRef<any>();
  const { chart, setShow } = useChartWrapper({
    type: ChartType.Bar,
    ref: chartContainer,
    ...chartData,
  });

  return (
    <div id="tabs-container" className="w-100">
      <Tabs
        defaultActiveKey="cuadro"
        id="uncontrolled-tab-example"
        className="mb-3 w-100"
        onSelect={(event: any) => setShow(event === "grafico")}
      >
        <Tab className="tabs-padding" eventKey="cuadro" title={t("table")}>
          <div className="tab-container">
            <h4>{title}</h4>
            <hr />
            {showTable && (
              <CustomTable headers={header} rows={rows}></CustomTable>
            )}
          </div>
        </Tab>
        <Tab className="tabs-padding" eventKey="grafico" title={t("chart")}>
          <div>
            <h4>{title}</h4>
            <hr />
          </div>
          <div className="w-100 h-100" ref={chartContainer}>
            {chart && chart}
          </div>
        </Tab>

        {showMethodologicalSheet && (
          <Tab className="tabs-padding" eventKey="sheet" title={t("sheet")}>
            <div className="tab-container">
              <div className="d-flex justify-content-between">
                <h3>{methodologicalSheet?.name}</h3>
                <Button
                  onClick={() => openInNewTab(methodologicalSheet?.source?.url)}
                  variant="primary"
                >
                  {t("download")}
                </Button>
              </div>
              <hr />
            </div>
          </Tab>
        )}

        {showMapFrame && (
          <Tab className="tabs-padding" eventKey="map" title={t("map")}>
            <div className="tab-container">
              <div className="d-flex flex-column justify-content-between">
                <h3>{mapFrame.name}</h3>
                <div className="map-container h-100 w-100">
                  <iframe
                    className="map-container w-100 h-100"
                    src={mapFrame.metadata.map_url}
                  ></iframe>
                </div>
              </div>
              <hr />
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default TopicDetailTabs;
