import { useEffect, useState } from "react";
import {
  CHART_SUB_TYPE_BAR,
  MAP,
  METHODOLOGICAL_SHEET,
  TABLE_TYPE,
} from "../../../../constants/components-const";
import {
  AxisLabels,
  BarChartColors,
  ChartProps,
} from "../../../../D3Module/interfaces/commont";
import { Indicator } from "../../../../types/api/indicators";

interface Hook {
  showTable: boolean;
  header: string[];
  rows: string[][];
  showMethodologicalSheet: boolean;
  methodologicalSheet: any;
  title: string;
  mapFrame: any;
  showMapFrame: boolean;
  chartData: {
    data: any[];
    labels: AxisLabels;
    colors?: string[] | BarChartColors;
  };
}

export function useTopicDetailTabs(indicator: Indicator[]): Hook {
  const [showTable, setShowTable] = useState(false);
  const [header, setHeader]: any = useState([]);
  const [rows, setRows]: any = useState([]);
  const [showMethodologicalSheet, setShowMethodologicalSheet] = useState(false);
  const [methodologicalSheet, setMethodologicalSheet]: any = useState();
  const [showMapFrame, setShowMapFrame] = useState(false);
  const [mapFrame, setMapFrame]: any = useState();
  const [title, setTile] = useState("");
  const [chartData, setChartData] = useState<ChartProps>({
    colors: {} as any,
    data: [] as any[],
    labels: {
      x: "",
      y: "",
    },
  });

  useEffect(() => {
    const tableData = indicator.find((x) => x.type === TABLE_TYPE);

    if (tableData) {
      setTile(tableData?.name);
      loadTable();
    } else {
      setShowTable(false);
      setHeader([]);
      setRows([]);
    }

    const sheetData = indicator.find((x) => x.type === METHODOLOGICAL_SHEET);

    if (sheetData) {
      loadMethodologicalSheet(sheetData);
    } else {
      setMethodologicalSheet(null);
      setShowMethodologicalSheet(false);
    }

    const hasMapFeatuere = indicator.find((x) => x.type === MAP);

    if (hasMapFeatuere) {
      setMapFrame(hasMapFeatuere);
      setShowMapFrame(true);
    } else {
      setMapFrame(null);
      setShowMapFrame(false);
    }
  }, [indicator]);

  const loadMethodologicalSheet = (sheetData: Indicator) => {
    setMethodologicalSheet(sheetData);
    setShowMethodologicalSheet(true);
  };

  const setUpChartData = (
    chartData: any,
    headers: string[],
    rows: string[][]
  ) => {
    const data = csvToJson(headers, rows);

    for (const element of data) {
      element[chartData.yfield] = element[chartData.yfield].replace(",", ".");
    }

    setChartData({
      colors: { barColor: "blue", xColor: "black", yColor: "black" }, // TODO: get colors
      data: data,
      labels: {
        x: chartData.xfield,
        y: chartData.yfield,
      },
    });
  };

  const loadTable = () => {
    const tableData = indicator.find((x) => x.type === TABLE_TYPE);
    const data = tableData?.data as string[][];
    const headers = data[0];
    setHeader(data[0]);
    data.shift();
    setRows(data);
    setShowTable(true);

    if (tableData && tableData.metadata["chart_type"] === CHART_SUB_TYPE_BAR) {
      setUpChartData(tableData.metadata, headers, data);
    }
  };

  const csvToJson = (headers: string[], rows: string[][]) => {
    const jsonList = [];
    for (const element of rows) {
      const newElement: any = {};
      for (let i = 0; i < element.length; i++) {
        newElement[headers[i]] = element[i];
      }
      jsonList.push(newElement);
    }
    return jsonList;
  };

  return {
    showTable,
    header,
    rows,
    showMethodologicalSheet,
    methodologicalSheet,
    title,
    mapFrame,
    showMapFrame,
    chartData,
  };
}
