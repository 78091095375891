import {
  FeatureCardSection,
  FrequentQuestionsSection,
  HowItWorksSection,
} from "../../organims";
import "./styles.scss";
import { HOME_BANNER } from "../../../assets";
import { useTranslation } from "react-i18next";
import useIndicators from "../../../hooks/useIndicators";

const Home = () => {
  const { t } = useTranslation("home");
  const { indicators } = useIndicators();

  return (
    <div className="w-100 d-flex justify-content-md-start justify-content-lg-start flex-column">
      <div className="w-100">
        <div className="w-100 banner-img-container d-flex justify-content-center">
          <div className="dark-background"></div>
          <img src={HOME_BANNER} className="banner-img" alt="..." />
          <div className="banner-content w-75 h-100 flex-column d-flex align-items-start justify-content-center">
            <div className="banner-text mb-4">
              <h1 className="mb-4">{t("section")}</h1>
              <p>{t("sectionDescription")}</p>
            </div>
          </div>
        </div>
        <div className="w-100 banner-bar"></div>
        <div className="w-100 d-flex flex-column align-items-center background-color">
          <div className="w-75  feature-section-container">
            <FeatureCardSection cardList={indicators} isDark={false} />
          </div>
        </div>
        <div className="w-100 d-flex flex-column align-items-center background-color">
          <div className="w-75 mt-3">
            <HowItWorksSection />
          </div>
        </div>
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="col-lg-6 col-10 mt-5">
            <FrequentQuestionsSection questionNumber={3} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
