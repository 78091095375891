import { useTranslation } from "react-i18next";
import { availableLanguages } from "../../../i18n/languages";
import "./styles.scss";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  return (
    <div className="switcher d-flex flex-column justify-content-center">
      <select
        className="switcher-select"
        value={i18n.language}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
      >
        {availableLanguages.map((lng, i) => (
          <option value={lng.value} key={i}>
            {lng.name}
          </option>
        ))}
      </select>
    </div>
  );
};
export default LanguageSwitcher;
