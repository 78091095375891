import React, { FC } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Footer } from "..";
import { MINAE_ICON, SINIA_ICON } from "../../../assets";
import {
  CONTACT,
  HOME,
  ABOUT,
  STATISTICS,
  DOCS,
} from "../../../constants/routes";
import LanguageSwitcher from "../../moleculas/LanguageSwitcher";
import "./styles.scss";

const Header: FC<any> = ({ children }) => {
  const { t } = useTranslation("header");

  return (
    <div className="w-100">
      <div className="w-100">
        <Navbar className="shadow" collapseOnSelect expand="lg">
          <Container className="w-100 d-flex justify-content-around">
            <Navbar.Brand href="#home">
              <img
                id="logo-sinia"
                src={SINIA_ICON}
                className="d-inline-block align-top"
                alt="Logo SINIA"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="m-auto">
                <Link className="nav-link" to={HOME}>
                  {t("home")}
                </Link>
                <Link className="nav-link" to={ABOUT}>
                  {t("about")}
                </Link>
                <Link className="nav-link" to={STATISTICS}>
                  {t("statistics")}
                </Link>
                <Link className="nav-link" to={DOCS}>
                  {t("documentation")}
                </Link>
                <Link className="nav-link" to={CONTACT}>
                  {t("contact")}
                </Link>
                <LanguageSwitcher />
              </Nav>
            </Navbar.Collapse>
            <Navbar.Brand href="#home">
              <img
                id="logo-minae"
                src={MINAE_ICON}
                className="d-inline-block align-top"
                alt="Logo MINAE"
              />
            </Navbar.Brand>
          </Container>
        </Navbar>
      </div>
      {children}
      <Footer />
    </div>
  );
};

export default Header;
