import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useContactForm } from "./useContactForm";
import "./styles.scss";

const ContactForm = () => {
  const { t } = useTranslation("contact");
  const { formik, isSubmitting } = useContactForm();

  return (
    <div>
      {formik.status && formik.status.success && (
        <div className="send-message mb-3">{t("success")}</div>
      )}
      {isSubmitting ? (
        <Spinner animation="border" variant="dark" />
      ) : (
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>{t("name")}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name && (
              <Form.Text className="error-message">
                {formik.errors.name}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>{t("email")}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email && (
              <Form.Text className="error-message">
                {formik.errors.email}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>{t("subject")}</Form.Label>
            <Form.Control
              type="text"
              name="subject"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.subject}
            />
            {formik.touched.subject && formik.errors.subject && (
              <Form.Text className="error-message">
                {formik.errors.subject}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
            <Form.Label>{t("message")}</Form.Label>
            <Form.Control
              as="textarea"
              name="message"
              rows={3}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
            />
            {formik.touched.message && formik.errors.message && (
              <Form.Text className="error-message">
                {formik.errors.message}
              </Form.Text>
            )}
          </Form.Group>
          <Button
            className="custom-primary-button w-100"
            type="submit"
            disabled={isSubmitting || !formik.isValid}
          >
            {t("sendMessage")}
          </Button>
        </Form>
      )}
    </div>
  );
};

export default ContactForm;
