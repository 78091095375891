import {
  GET_INDICATORS_FAILURE,
  GET_INDICATORS_STARTED,
  GET_INDICATORS_SUCCESS,
} from "../../constants/reduceActionTypes";

const getIndicatorsStart = () => ({
  type: GET_INDICATORS_STARTED,
});

const getIndicatorsSuccess = (payload: any) => ({
  type: GET_INDICATORS_SUCCESS,
  payload: payload,
});

const getIndicatorsFailure = (error: any) => ({
  type: GET_INDICATORS_FAILURE,
  payload: error,
});

export { getIndicatorsStart, getIndicatorsSuccess, getIndicatorsFailure };
