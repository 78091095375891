import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BannerSection,
  FrequentQuestionsSection,
  GeoenvironmentalSection,
} from "../../organims";
import LogoSection from "../../organims/About/LogoSection";
//import "./styles.scss";

const About = () => {
  const { t } = useTranslation("about");

  useEffect(() => {
    document.title = `SINIA - ${t("sectionName")}`;
  }, []);

  return (
    <div className="w-100 d-flex  justify-content-md-start justify-content-lg-start flex-column">
      <div className="w-100 d-flex flex-column align-items-center background-color">
        <BannerSection />
      </div>
      <div className="w-100 d-flex mt-5 flex-column align-items-center">
        <div className="w-75 mt-4">
          <LogoSection />
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center background-color">
        <div className="w-75 mt-5 p-3">
          <GeoenvironmentalSection />
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="col-lg-6 col-10 mt-5">
          <FrequentQuestionsSection questionNumber={3} />
        </div>
      </div>
    </div>
  );
};

export default About;
