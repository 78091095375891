import { SEND_EMAIL } from "./../../constants/endpoints";
import ServerError from "../../types/ServerError";
import { throwable } from "../../utilities/ts-throwable";
import ApiClient from "./apiClient";

class EmailService {
  protected readonly apiClient: ApiClient;

  public constructor() {
    this.apiClient = new ApiClient();
  }

  postNotification = (payload: any): Promise<any> & throwable<ServerError> => {
    return this.apiClient.post(SEND_EMAIL, payload);
  };
}

export default EmailService;
