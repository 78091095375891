import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HOME, QUESTIONS } from "../../../constants/routes";
import { CustomBreadcrumb } from "../../moleculas";
import { FrequentQuestionsSection } from "../../organims";

const FAQ = () => {
  const { t } = useTranslation("questions");

  useEffect(() => {
    document.title = `SINIA - ${t("title")}`;
  }, []);

  return (
    <div className="w-100 d-flex justify-content-center flex-column justify-content-start ">
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="w-75 breadcrumb-height">
          <CustomBreadcrumb
            isWhite={false}
            breadcrumbs={[
              {
                name: t("home"),
                href: HOME,
              },
              { name: t("title"), href: QUESTIONS },
            ]}
          />
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="col-lg-6 col-10 mt-5">
          <FrequentQuestionsSection />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
