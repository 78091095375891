import React, { FC } from "react";
import { SINIA_ICON } from "../../../../assets";
import "./styles.scss";

export interface TopicDetailHeaderProps {
  name: string;
  iconSrc?: string;
}

const TopicDetailHeader: FC<TopicDetailHeaderProps> = ({ iconSrc, name }) => {
  return (
    <div className="w-100  p-4 d-flex align-items-stretch topic-header-container">
      <div className="img-container">
        <img alt="Imagen topic" src={iconSrc ? iconSrc : SINIA_ICON} />
      </div>
      <div className="card-txt-container h-100 d-flex align-items-center">
        <h3>{name}</h3>
      </div>
    </div>
  );
};

export default TopicDetailHeader;
